/* eslint-disable camelcase */

import React from 'react'
import { Alert, Button, Modal } from 'react-bootstrap'
import { useGetListingQuery, useUploadListingBenchmark } from '../../../components/api'
import { useGetListingLotSkusQuery } from '../../../components/api/BuyerApi'
import { CreateFileSelect } from '../../../components/Form'
import Spinner from '../../../components/spinner'
import { NOOP, quickAndDirtyCSV } from '../../../components/utils'

/**
 * Returns true if awardLevelSelection is 'award_sku.
 */
const isAwardLevelSelectionASku = (awardLevelSelection) => awardLevelSelection === 'award_sku'

/**
 *
 * ANCHOR Page
 *
 * @param {Object} props
 * @param {boolean} props.openModal
 * @param {Function} props.setOpenModal
 * @returns
 */
const Page = (props) => {
  // props
  const { openModal = true, setOpenModal = NOOP } = props
  const { listingId = '325' } = props

  // query
  const listingQuery = useGetListingQuery({ listingId, isVendor: true })
  const {
    isLoading = false, data: {
      end_time: endTime = null,
      listing_lots: listingLots = [],
      award_level_selection: awardLevelSelection = null
    } = {}
  } = listingQuery
  const isSkuAward = isAwardLevelSelectionASku(awardLevelSelection)
  const listingLotSkusQuery = useGetListingLotSkusQuery({ listingId, listingLotId: 'all', listingLots, isVendor: true })
  const { data: { listing_lot: listingLot = {} } = {} } = listingLotSkusQuery
  const { listing_lot_skus: listingLotSkus = [] } = listingLot
  const uploadListingBenchmark = useUploadListingBenchmark()

  // refs
  const formRef = React.useRef()

  // state
  const [file, setFile] = React.useState(undefined)
  const [benchmarkFileHref, setBenchmarkFileHref] = React.useState('#')

  // setup the csv download for benchmark...
  React.useEffect(() => {
    // convert SKUs to csv...
    if (listingLotSkusQuery.isSuccess && isSkuAward) {
      const header = quickAndDirtyCSV(['Listing_id', 'Listing_lot_id', 'Lot_number', 'Listing_lot_sku_id', 'Sku_description', 'Grade', 'Quantity', 'Benchmark (Unit Price)'])
      const body = listingLotSkus.map((lot) => {
        const { listingLotId, listing_lot_number: lotNumber, listing_lot_sku_id: skuId, sku_description, grade, quantity, benchmark = null } = lot
        return quickAndDirtyCSV([listingId, listingLotId, lotNumber, skuId, sku_description, grade, quantity, benchmark === null ? '' : benchmark])
      }).join('') + '\n'
      const csv = '\uFEFF' + header + body
      setBenchmarkFileHref('data:text/csv;charset=utf-8,' + encodeURI(csv))
    }
  }, [listingLotSkusQuery, setBenchmarkFileHref, listingId, isSkuAward, listingLotSkus])

  React.useEffect(() => {
    // convert LOTs to csv...
    if (listingQuery.isSuccess && !isSkuAward) {
      const header = quickAndDirtyCSV(['Listing_id', 'Listing_lot_id', 'Lot_number', 'Lot_description', 'Grade', 'Quantity', 'Benchmark'])
      const body = listingQuery.data.listing_lots.map(lot => {
        const { id, lot_number: lotNumber, lot_description, grade, quantity, benchmark = null } = lot
        return quickAndDirtyCSV([listingId, id, lotNumber, lot_description, grade, quantity, benchmark === null ? '' : benchmark])
      }).join('') + '\n'
      const csv = '\uFEFF' + header + body
      setBenchmarkFileHref('data:text/csv;charset=utf-8,' + encodeURI(csv))
    }
  }, [listingQuery, setBenchmarkFileHref, listingId, isSkuAward])

  // functions
  const onHide = () => {
    clearFile()
    setOpenModal(false)
    uploadListingBenchmark.reset()
  }
  const internalOnSave = () => {
    uploadBenchmarkFile()
    clearFile()
  }

  const clearFile = () => {
    setFile(undefined)
    // N.B. the only way to reliably clear the input[type=file]!
    formRef.current.reset()
  }

  const onChangeFile = (e) => {
    e.preventDefault()
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0])
    }
  }

  /**
   * Upload the product manifest file.
   */
  const uploadBenchmarkFile = () => {
    if (typeof file !== 'undefined') {
      console.debug('Sending benchmark to backend...')
      uploadListingBenchmark.mutate({
        listingId,
        formData: {
          'listing[benchmark_csv_file]': file
        }
      })
    }
  }

  return <>

        <Modal show={openModal} onHide={onHide}>
          <Modal.Body>
                <h5 className="m-0 mb-3">Upload benchmark</h5>

                <p>Upload the benchmark by filling in the <a
                href={benchmarkFileHref}
                download={`benchmark-listing-${listingId}.csv`}
                target="_blank" rel="noreferrer"
                className="font-weight-bold text-capitalize"
                >benchmark template</a>.</p>

                {/* choose benchmark file */}
                <form ref={formRef}>
                  <CreateFileSelect
                    label="Upload benchmark file (.CSV format only)"
                    value={file}
                    onChange={e => onChangeFile(e)}
                    />
                </form>

                {/* buttons */}
                <div className="d-flex justify-content-between mt-5">
                    <Button type="button" onClick={onHide} variant="outline-primary">Cancel</Button>
                    <Button type="button" onClick={internalOnSave} variant="primary" disabled={typeof file === 'undefined'}>
                        {uploadListingBenchmark.isLoading ? <Spinner /> : <span>Upload</span>}
                    </Button>
                </div>

                { process.env.NODE_ENV !== 'production' && <Button className="mt-3" onClick={() => clearFile()}>Clear File</Button>}

                { uploadListingBenchmark.isSuccess && <Alert variant="success" className="mb-0 mt-3 text-center">Upload successful!</Alert> }
                { uploadListingBenchmark.isError && <Alert variant="danger" className="mb-0 mt-3 text-center">{'Error updating file. ' + uploadListingBenchmark.error.message}</Alert> }

            </Modal.Body>
        </Modal>
      </>
}

export default Page
